// Utils
/**
 * @file Website paths specific to the webclient (node.js) site.
 */
import { WebServerExternalPath } from "lib-fullstack/utils/paths";

/**
 * Paths for the web server site. These should only be accessed as paths
 * relative to the web server site.
 * https://docs.google.com/document/d/1hqaC4xpEbCI1S9DbgMCc70pK-fmSCoiKLioPVFpW8Dg/edit#heading=h.xxudglj7to2z
 */
export enum WebServerInternalPath {
  ACCEPT_INVITE = "/accept-invite",
  ADMIN_CONFIG = "/admin-config",
  ADMIN_CONFIG_ORG_DETAILS = "/admin-config/:orgId",
  AFFILIATE_PROGRAM = "/affiliate-program",
  BUILDER = "/builder",
  CHECKOUT = "/checkout",
  CHECKOUT_COMPLETE = "/checkout-complete",
  DASHBOARD = "/dashboard",
  DOWNLOAD_POODLI_DIRECT_DEPRECATED = "/download-private-yoodli",
  EMAIL_VERIFIED = "/email-verified",
  EXERCISES_DEPRECATED = "/exercises/",
  EXERCISES_METAPHOR_MANIA = "/exercises/metaphor-mania",
  EXERCISES_NO_FILLER = "/exercises/no-filler",
  EXERCISES_SPIN_A_YARN = "/exercises/spin-a-yarn",
  EXERCISES_STORYTELLER = "/exercises/storyteller",
  FORGOT_PASSWORD = "/forgot-password",
  FOR_MEETINGS_DEPRECATED = "/for-meetings",
  GAMES_METAPHOR_MANIA_DEPRECATED = "/games/metaphor-mania",
  GAMES_NO_FILLER_DEPRECATED = "/games/no-filler",
  GAMES_SPIN_A_YARN_DEPRECATED = "/games/spin-a-yarn",
  GAMES_STORYTELLER_DEPRECATED = "/games/storyteller",
  GOTO = "/goto",
  HOME = "/",
  HOMEPAGE = "/home",
  IMPROMPTU_PROMPT_DEPRECATED = "/record-impromptu",
  INTERVIEW_DEPRECATED = "/interview",
  LEARNING_DEPRECATED = "/learning/",
  LIBRARY = "/library",
  LIBRARY_USER_FEEDBACK_SPEECH = "/library/:userId/feedback/:speechId",
  ONBOARDING = "/onboarding",

  ORG_SETTINGS = "/org/settings",
  ORG_GROUPS = "/org/groups",
  CREATE_GROUP = "/org/groups/create",
  ORG_OVERVIEW = "/org/overview",
  ORG_MEMBERS = "/org/members",
  ORG_CONTENT = "/org/content",
  CREATE_ORG = "/org/create",
  ORG_REPORTING_TEAMS = "/org/reporting-teams",

  PRACTICE_INTERVIEW_DEPRECATED = "/practice-interview",
  PRACTICE_RECORDER_DEPRECATED = "/practice",
  PRIVATE_YOODLI_DEPRECATED = "/private-yoodli",
  RECORD_SPEECH_DEPRECATED = "/record-speech",
  RESTRICTED = "/restricted",
  /**
   * For the "Finish Setting Up the Desktop App" page.
   */
  SETUP_DESKTOP_APP = "/setup-desktop-app",
  SHARE_SLUG = "/share/:slug",
  SHARE_SLUG_NAME = "/share/:slug/:name",
  SHARED_DEPRECATED = "/shared/",
  SUMMARY = "/summary/",
  VERIFY_EMAIL = "/verify-email",
  ZOODLIBOT_DEPRECATED = "/zoodlibot",
}

export const PUBLIC_WEBSITE_PATHS: string[] = [
  WebServerExternalPath.SIGN_IN,
  // We need to keep login around as well as signin, as TMI is still stuck using login
  WebServerExternalPath.LOG_IN,
  WebServerExternalPath.SIGN_UP,
  WebServerInternalPath.FORGOT_PASSWORD,
  WebServerExternalPath.DOWNLOAD_POODLI_DIRECT,
  WebServerExternalPath.RESET_PASSWORD,
  WebServerExternalPath.SUPPORT,
  WebServerExternalPath.SHARE,
  WebServerInternalPath.SHARE_SLUG,
  WebServerInternalPath.RESTRICTED,
  WebServerExternalPath.INTEGRATION_ERROR,
  WebServerInternalPath.EMAIL_VERIFIED,
];

export const CUSTOM_TITLE_PAGES: string[] = [WebServerExternalPath.SHARE];
